import React from "react";
import PropTypes from "prop-types";
import Layout from "../../../../components/Layout/Layout";
import IndexPage from "../../IndexPage/IndexPage";
import { graphql } from "gatsby";

const BlogIndex = ({ data, pageContext, location }) => (
	<Layout pageContext={pageContext}>
		<IndexPage data={data} location={location} pageContext={pageContext} />
	</Layout>
);

BlogIndex.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
	location: PropTypes.object.isRequired,
	data: PropTypes.shape({
		allGhostPost: PropTypes.object.isRequired,
		allGhostSettings: PropTypes.object.isRequired,
	}).isRequired,
};

export default BlogIndex;

export const pageQuery = graphql`
	query GhostPostQuery($limit: Int!, $skip: Int!, $lang: String!) {
		allGhostPost(
			sort: { order: DESC, fields: [published_at] }
			filter: { slug: { ne: "data-schema" }, tags: { elemMatch: { name: { eq: $lang } } } }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					...GhostPostFields
				}
			}
		}
		allGhostSettings {
			edges {
				node {
					navigation {
						label
						url
					}
				}
			}
		}
	}
`;
